import './style.scss';
import { Slider } from '../../../js/components/Slider/Slider';

export default !function () {
  const block = document.querySelector('.process-v3');

  if (!block) {
    return;
  }

  const sliderContainer = block.querySelector('.process-v3__slider');
  const slides = sliderContainer.querySelectorAll('.process-v3__item');
  const controls = sliderContainer.querySelector('.process-v3__controls');
  const isInit = slides.length > 1;

  const options = {
    wrapper: sliderContainer,
    settings: {
      spaceBetween: 8,
      slideClass: 'process-v3__item',
      wrapperClass: 'process-v3__list',
      effect: 'slide',
      loop: false,
      fadeEffect: {
        crossFade: true
      },

      navigation: {
        nextEl: '.button-arrow--next',
        prevEl: '.button-arrow--prev'
      },

      pagination: {
        el: '.process-v3__current',
        type: 'fraction'
      },

      media: {
        lg: {
          loop: true,
          effect: 'fade'
        },
        xl: {
          loop: true,
          effect: 'fade'
        }
      },

      breakpoints: {
        1024: {
          spaceBetween: 0
        }
      },
    },
    modules: ['navigation', 'effectFade', 'pagination', 'a11y'],
    initCondition: {
      sm: isInit,
      md: isInit,
      lg: isInit,
      xl: isInit
    },
    controls
  };

  new Slider(options).init();

  // analytics
  block.addEventListener('click', (evt) => {
    const target = evt.target.closest('[data-analytics]');

    if (!target) {
      return;
    }

    switch (target.dataset.analytics) {
      case 'video-block':
        window.$analytics && window.$analytics.push('process-watch-video');
        break;

      case 'video-slide':
        window.$analytics && window.$analytics.push('process-slide-watch-video');
        break;
    }
  });
}();
